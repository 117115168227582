import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import InputLabel from 'evcharging/components/InputLabel';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue?: (value: string) => void;
  invalid?: boolean;
  grey?: boolean;
  label?: string;
  tooltip?: string;
  noSpace?: boolean;
  onLabelClick?: () => void;
  onInputClick?: () => void;
  smallLabel?: boolean;
};

const Input: FC<InputProps> = (props) => {
  const {
    onChangeValue,
    label,
    required,
    tooltip,
    onLabelClick,
    onInputClick,
    children,
    invalid,
    grey,
    noSpace,
    smallLabel = false,
    ...rest
  } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { invalid, grey, noSpace });

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChangeValue && onChangeValue(event.target.value);

  if (props.type === 'hidden') {
    return <input {...rest} onChange={onChange} />;
  }

  return (
    <div className={styles.root}>
      {label && (
        <InputLabel
          label={label}
          required={required}
          tooltip={tooltip}
          onClick={onLabelClick}
          small={smallLabel}
        />
      )}
      <input className={styles.input} {...rest} onChange={onChange} onClick={onInputClick} />
      {children}
    </div>
  );
};

const createStyles = (
  theme: Theme,
  { invalid = false, grey = false, noSpace = false }: Partial<InputProps>
) => ({
  root: css`
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  `,
  input: css`
    display: block;
    width: 100%;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    margin-bottom: ${noSpace ? '0' : '24px'};
    border-radius: 5px;
    padding: 1rem;
    color: #000;
    outline: none;
    border: 1px solid ${invalid ? '#eb5757' : '#ebebeb'};
    ${grey && 'background: #f6f8f9;'}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;

    :focus {
      border: 1px solid ${invalid ? '#eb5757' : theme.colors.accent};
    }
  `,
});

export default Input;
